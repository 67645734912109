import React from 'react';
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
// import Title from '../Components/Title';
import SmallTitle from '../Components/SmallTitle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';
import ResumeItem from '../Components/ResumeItem';

function Resume() {
    const briefcase = <BusinessCenterIcon />
    const school = <SchoolIcon />
    return (
        <ResumeStyled>
            {/* <Title title={'Resume'} span={'resume'} /> */}
            <InnerLayout>
                <div className="small-title">
                    <SmallTitle icon={briefcase} title={'Working Experience'} />
                </div>
                <div className="resume-content"> 
                    <ResumeItem 
                        year={'08, 2021 - Present'} 
                        title={'BDEMR Solutions Corp.'}
                        subTitle={'Full Stack Developer'}
                        text={'Developed client-side application using React frontend and NodeJS backend. Developed custom admin, user, super-admin dashboard using react. Developed hotel booking site using React frontend and NodeJs backend.'} 
                    />
                    <ResumeItem 
                        year={'05, 2021 - 08, 2021'} 
                        title={'Applore Technologies'}
                        subTitle={'MERN Stack Developer'}
                        text={'Developed client-side application using React frontend and NodeJS backend. Developed custom admin dashboard using react, and using many react library. Developed medical test site using React frontend also Developed backend with NodeJS '} 
                    />
                    
                </div>
                <div className="small-title u-small-title-margin">
                    <SmallTitle icon={school} title={'Educational Qualifications'} />
                </div>
                <div className="resume-content "> 
                    <ResumeItem 
                        year={'2020 - Present'} 
                        title={'Intermediate'}
                        subTitle={'Singra Damdama Pilot School & College'}
                        text={'Singra Damdama Pilot School & College  is an academic institute located at Domdoma Singra.  It was established on 01 January, 1954. The institute has following 3 disciplines.  It has Day shift.  Its management is Governing body.'} 
                    />
                    <ResumeItem 
                        year={'2018 - 2020'} 
                        title={'High School Graduation'}
                        subTitle={'Kaligram Rathindranath Institution'}
                        text={'Kaligram Rathindra Nath Institution is an academic institute located at Patisar Atrai Naogaon. Its institute code (EIIN) is 123031. It was established on 02 January, 1937. The institute has following 3 disciplines : Business Studies, Humanities, Science. '} 
                    />
                </div>
            </InnerLayout>
        </ResumeStyled>    
    )
}

const ResumeStyled = styled.section`
    .small-title{
        padding-bottom: 3rem;
    }
    .u-small-title-margin{
        margin-top: 4rem;
    }

    .resume-content{
        border-left: 2px solid var(--border-color);
    }
`;
export default Resume

 
import blog1 from '../img/blog1.png';
import blog2 from '../img/blog2.png';
import blog3 from '../img/blog3.png';
import blog4 from '../img/blog4.png'; 
 

const blogs = [
    {
        id: 1,
        image: blog1,
        title: 'Some things you should be known as a javascript developer',
        link: 'https://shoharabpk-2019.medium.com/10-things-you-should-be-known-as-a-javascript-developer-6fad58b59f75' ,
        date: '01',
        month: 'April',
    },
    {
        id: 2,
        image: blog2,
        title: '10 best think in react!',
        link: 'https://shoharabpk-2019.medium.com/10-best-think-in-react-a2b6939d48f2' ,
        date: '01',
        month: 'April',
    },
    {
        id: 3,
        image: blog3,
        title: '10 javascript thing you must be known as a javascript developer',
        link: 'https://shoharabpk-2019.medium.com/10-javascript-thing-you-must-be-known-as-a-javascript-developer-3e767c98c01d' ,
        date: '01',
        month: 'April',
    },
    {
        id: 4,
        image: blog4,
        title: 'What is JavaScript?',
        link: 'https://shoharabpk-2019.medium.com/what-is-javascript-96183d70ed13' ,
        date: '01',
        month: 'April',
    }
];
export default blogs;
import React from 'react';
import styled from 'styled-components';
import resume from '../img/shoharab.JPG';
import PrimaryButton from './PrimaryButton';

function ImageSection() {
    return (
        <ImageSectionStyled>
            <div className="left-content">
                <img src={resume} alt="" />
            </div>
            <div className="right-content">
                <h4>I am <span>Md Shoharab Pk</span></h4>
                <p className="paragraph">
                    I am a Web Developer. I count myself as a hardworking person. Honesty, hard work,
                    and passion will support me to grow myself & utilize my knowledge and expertise for
                    professional development in the relevant field.
                </p>
                <div className="about-info">
                    <div className="info-title">
                        <p>Full Name</p>
                        <p>Age</p>
                        <p>Nationality </p>
                        <p>Languages </p>
                        <p>Location</p>
                        <p>Service</p>
                    </div>
                    <div className="info">
                        <p>: Md Shoharab Pk</p>
                        <p>: 19</p>
                        <p>: Bangladesh </p>
                        <p>: English, Bangla, Hindi </p>
                        <p>: Rajshahi, Bangladesh</p>
                        <p>: Web Application</p>
                    </div>
                </div>
                <a href="https://drive.google.com/uc?id=1t-vhxyrmRbbBBAnPqYsEkn94ou1zYN_1&export=download" download="Resume of Shoharab Pk">
                <PrimaryButton type="button" title={'Download Cv'} />
                </a>
            </div>
        </ImageSectionStyled>
    )
}


const ImageSectionStyled = styled.div`
    margin-top: 5rem;
    display: flex;
    @media screen and (max-width:1000px){
        flex-direction: column;
        .left-content{
            margin-bottom: 2rem;
        }
    }
    .left-content{
        width: 100%;
        img{
            width: 95%;
            object-fit: cover;
        }
    }
    .right-content{
        width: 100%;
        h4{
            font-size: 2rem;
            color: var(--white-color);
            span{
                font-size: 2rem;
            }
        }
        .paragraph{
            padding: 1rem 0;
        }
        .about-info{
            display: flex;
            padding-bottom: 1.4rem;
            .info-title{
                padding-right: 3rem;
                p{
                    font-weight: 600;
                }
            }
            .info-title, .info{
                p{
                    padding: .3rem 0;
                }
            }
        }
    }
`;
export default ImageSection;

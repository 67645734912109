import port1 from '../img/internetservice.png'; 
import port11 from '../img/e_team.png'
import port12 from '../img/portfolio.png';
import port13 from '../img/chat-application.png';
import port14 from '../img/portImages/dreamland.png';
import port15 from '../img/aspire.png'
 
const portfolios = [
    {
        id: 15,
        category: 'MERN-Stack',
        link1: 'https://dreamland-bd.com/',
        link2: '#', 
        image: port14,
        title: 'Dreamland BD'
    },
    {
        id: 14,
        category: 'React Js',
        link1: 'https://aspiretechnologiez.com/',
        link2: '#', 
        image: port15,
        title: 'Aspire Technologiez'
    },
    {
        id: 13,
        category: 'Full-Stack',
        link1: 'https://chat-with-your-honey.netlify.app/',
        link2: 'https://github.com/web-shoharab-pk/chatting_application', 
        image: port13,
        title: 'Chat with You Honey'
    },
    {
        id: 11,
        category: 'Full-Stack',
        link1: 'https://eteammanage.web.app/',
        link2: 'https://github.com/web-shoharab-pk/E-Team', 
        image: port11,
        title: 'E-Team'
    },
    {
        id: 12,
        category: 'React Js',
        link1: 'https://shoharabpk-portfolio.web.app/',
        link2: 'https://github.com/web-shoharab-pk/', 
        image: port12,
        title: 'Portfolio'
    },
    {
        id: 1,
        category: 'Full-Stack',
        link1: 'https://internet-service-f1fbf.web.app/',
        link2: 'https://github.com/web-shoharab-pk/internet-service', 
        image: port1,
        title: 'Internet Service'
    }
]

export default portfolios;